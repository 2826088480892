<template>
    <div class="app flex-row align-items-center">

        <b-row style="max-width: 100%;padding-left:0px !important;padding-right: 0px; !important;margin: 0px;">


            <div v-if="show == true" id="principal" class="col-md-12">
                <mdb-row class="mt-5 align-items-center justify-content-start">
                    <h4 class="demo-title"><strong>Panel Administrativo</strong></h4>
                </mdb-row>

                <hr />

                <b-row>
                    <div class="col-md-3">
                        <mdb-card  class="card-body" style="margin-top: 1rem;min-height: 160px;background: #BBDEFB">
                            <div @click="IrUsuarios" style="cursor: pointer">
                                <mdb-card-title> <i style="padding-right: 10px" class="fas fa-users"></i> Usuarios</mdb-card-title>
                                <mdb-card-text>Gestión de los usuarios del sistema</mdb-card-text>
                                <div class="flex-row">
                                </div>
                            </div>
                        </mdb-card>
                    </div>
                    <div class="col-md-3">
                        <mdb-card class="card-body" style="margin-top: 1rem;min-height: 160px;background: #D1C4E9">
                            <div @click="IrNodos" style="cursor: pointer">
                                <mdb-card-title><i style="padding-right: 10px" class="fas fa-server"></i>Nodos</mdb-card-title>
                                <mdb-card-text>Gestión de los distintos servidores distribuidos de el sistema</mdb-card-text>
                                <div class="flex-row">
                            </div>
                            </div>
                        </mdb-card>
                    </div>
                    <div class="col-md-3">
                        <mdb-card class="card-body" style="margin-top: 1rem;min-height: 160px; background: #FFCCBC">
                            <div @click="IrDelegaciones" style="cursor: pointer">
                                <mdb-card-title><i style="padding-right: 10px" class="far fa-building"></i>Delegaciones</mdb-card-title>
                                <mdb-card-text>Gestión de las Delegaciones de el sistema</mdb-card-text>
                                <div class="flex-row">
                                </div>
                            </div>
                        </mdb-card>
                    </div>
                    <div class="col-md-3">
                        <mdb-card class="card-body" style="margin-top: 1rem;min-height: 160px; background:  #C8E6C9">
                            <div @click="IrVideos" style="cursor: pointer">
                            <mdb-card-title><i style="padding-right: 10px" class="fas fa-video"></i> Vídeos</mdb-card-title>
                            <mdb-card-text>Gestión de los vídeos de el sistema</mdb-card-text>
                            <div class="flex-row">
                            </div>
                            </div>
                        </mdb-card>
                    </div>
                </b-row>
                <b-row>
                    <div class="col-md-3">
                        <mdb-card class="card-body" style="margin-top: 1rem;min-height: 160px;cursor:pointer; background: #FFE0B2;">
                            <div @click="IrEventos">
                                <mdb-card-title><i style="padding-right: 10px" class="fas fa-calendar-day"></i> Eventos</mdb-card-title>
                                <mdb-card-text>Gestión de los eventos programados de el sistema</mdb-card-text>
                                <div class="flex-row">
                                </div>
                            </div>
                        </mdb-card>
                    </div>
                    <div class="col-md-3">
                        <mdb-card class="card-body" style="margin-top: 1rem;min-height: 160px;cursor:pointer; background: #FFCDD2">
                            <div @click="IrCanales" >
                                <mdb-card-title><i style="padding-right: 10px"  class="fas fa-tv"></i>Canales</mdb-card-title>
                                <mdb-card-text>Gestión de los canales de el sistema</mdb-card-text>
                                <div class="flex-row">
                                </div>
                            </div>
                        </mdb-card>
                    </div>
                </b-row>
                <div style="padding: 5px"></div>
                <hr />
                <div style="padding: 5px"></div>

                <b-row style="align-items: center;text-align: center;align-content: center;text-align-all: center;margin-left: 3%">
                    <div class="col-md-2" style="padding: 2em; ">
                        <b-row>
                            <h4>Usuarios Activos</h4>
                        </b-row>
                        <b-row>
                            <p><b>{{total_usuarios}}</b></p>
                        </b-row>
                    </div>
                    <div class="col-md-2" style="padding: 2em">
                        <b-row>
                            <h4>Usuarios Conectados</h4>
                        </b-row>
                        <b-row>
                            <p><b>{{usuarios_conectados}}</b></p>
                        </b-row>

                    </div>
                    <div class="col-md-2" style="padding: 2em">
                        <b-row>
                            <h4>Máximo Usuarios Online</h4>
                        </b-row>
                        <b-row>
                            <p><b>{{maximo_usuarios_online}}</b></p>
                        </b-row>

                    </div>
                    <div class="col-md-2" style="padding: 2em">
                        <b-row>
                            <h4>Visiones Totales</h4>
                        </b-row>
                        <b-row>
                            <p><b>{{visiones_totales}}</b></p>
                        </b-row>

                    </div>
                    <div class="col-md-2" style="padding: 2em">
                        <b-row>
                            <h4>Espacio en Disco Libre</h4>
                        </b-row>
                        <b-row>
                            <p><b>{{espacio_libre}} GB</b></p>
                        </b-row>

                    </div>
                    <div class="col-md-2" style="padding: 2em">
                        <b-row>
                            <h4>Likes Totales</h4>
                        </b-row>
                        <b-row>
                            <p><b>{{likes_totales}}</b></p>
                        </b-row>

                    </div>
                </b-row>

                <div style="padding: 5px"></div>
                <hr />
                <div style="padding: 5px"></div>

                <b-row>
                    <div class="col-md-6">
                        <mdb-card style="margin-top: 1rem; background: #FAFAFA">
                            <mdb-card-body>
                                <mdb-card-title>Últimos Registros</mdb-card-title>
                                <b-table style="background: white" striped hover  :fields="fields_ultimos_usuarios" :items="ultimos_usuarios"></b-table>
                            </mdb-card-body>
                        </mdb-card>
                    </div>
                    <div class="col-md-6">
                        <mdb-card style="margin-top: 1rem; background: #FAFAFA">
                            <mdb-card-body>
                                <mdb-card-title>Últimos eventos Programados</mdb-card-title>
                                <b-table  style="background: white" striped hover  :fields="fields_ultimos_eventos" :items="ultimos_eventos"></b-table>

                            </mdb-card-body>
                        </mdb-card>
                    </div>
                </b-row>
                <b-row>
                    <div class="col-md-6">
                        <mdb-card style="margin-top: 1rem; background: #FAFAFA">
                            <mdb-card-body>
                                <mdb-card-title>Últimas renderizaciones</mdb-card-title>
                                <b-table style="background: white" striped hover  :fields="fields_ultimos_renders" :items="ultimos_renders"></b-table>
                            </mdb-card-body>
                        </mdb-card>
                    </div>
                    <div class="col-md-6">
                        <mdb-card style="margin-top: 1rem; background: #FAFAFA">
                            <mdb-card-body>
                                <mdb-card-title>Estado Nodos</mdb-card-title>
                                <b-table style="background: white" striped hover  :fields="fields_estado_nodos" :items="estado_nodos"></b-table>
                            </mdb-card-body>
                        </mdb-card>
                    </div>
                </b-row>
                <b-row>

                </b-row>



            </div>

        </b-row>

        <vue-cookie-accept-decline
                :ref="'myPanel1'"
                :elementId="'myPanel1'"
                :debug="false"
                :position="'bottom-left'"
                :type="'floating'"
                :disableDecline="true"
                :transitionName="'slideFromBottom'"
                :showPostponeButton="false"
                @status="cookieStatus"
                @clicked-accept="cookieClickedAccept"
                @clicked-decline="cookieClickedDecline">

            <!-- Optional -->
            <div slot="postponeContent">
                &times;
            </div>

            <!-- Optional -->
            <div slot="message">
                Agustinos.tv cumple con la normativa de cookies y privacidad.<a href="https://agustinostv-front.archive.tornadocore.es/proteccion_de_datos.pdf" target="_blank">Leer más </a>
            </div>


            <!-- Optional -->
            <div slot="acceptContent">
                ACEPTAR
            </div>
        </vue-cookie-accept-decline>
    </div>



</template>

<script>

    import { mdbBreadcrumb,
        mdbBreadcrumbItem,
        mdbIcon,
        mdbRow,
        mdbContainer,
        mdbCard,
        mdbCardImage,
        mdbCardBody,
        mdbCardTitle,
        mdbCardText,
        mdbCol,
        mdbView,
        mdbBtn,
        mdbMask,
        mdbCardGroup,  } from 'mdbvue';
    import ApiService from '@/services/api';

    export default {
        name: 'PanelAdmin',
        components: {
            mdbBreadcrumb,
            mdbBreadcrumbItem,
            mdbContainer,
            mdbRow,
            mdbIcon,
            mdbCard,
            mdbCardImage,
            mdbCardBody,
            mdbCardTitle,
            mdbCardText,
            mdbView,
            mdbMask,
            mdbCol,
            mdbBtn,
            mdbCardGroup,
        },
        data() {
            return {
                show: false,
                token : localStorage.getItem('token'),
                novedades : [],
                ultimos_usuarios : [],
                ultimos_renders : [],
                estado_nodos: [],
                ultimos_eventos: [],
                admin_home_stats : null,
                total_usuarios : 0,
                usuarios_conectados : 0,
                maximo_usuarios_online : 0,
                visiones_totales : 0,
                espacio_libre: 0,
                likes_totales : 0,
                fields_ultimos_usuarios: ['id','username', 'email', 'active'],
                fields_ultimos_renders: ['id','estado', 'accion', 'id_video','porcentaje'],
                fields_ultimos_eventos: ['id','title', 'delegacion_id', 'views','likes','max_online','canal_id','estado'],
                fields_estado_nodos: ['id','url','uso_disco'],
                estilo_row : 'width: 100%;margin: 10px 30px;',
                esta_en_vertical: false,
                pagina_actual : 1,
                is_mobile:'',
                selected_delegacion : null,
                selected_tipo : null,
                selected_orden: 'DESC',
                selected_nombre : '',
                delegaciones:null,
            }
        },
        methods: {

            IrUsuarios()
            {
                this.$router.push({path: 'admin/usuarios'});
            },

            IrCanales()
            {
                this.$router.push({path: 'admin/canales'});
            },

            IrVideos()
            {
                this.$router.push({path: 'admin/videos'});
            },
            IrEventos()
            {
                this.$router.push({path: 'admin/eventos'});
            },

            IrNodos()
            {
                this.$router.push({path: 'admin/nodos'});
            },
            IrDelegaciones()
            {
                this.$router.push({path: 'admin/delegaciones'});
            },

            FechaSQLtoFechaES(entrada)
            {
                return  moment(String(entrada)).format('DD/MM/YYYY hh:mm:ss');
            },
            //Ordenar las novedades de más reciete a anterior (Son distintos tipos de objetos)
            compare(a, b) {

                var dateA = moment(a.created_at, "YYYY-MM-DD"); // replace format by your one
                var dateB = moment(b.created_at, "YYYY-MM-DD");

                if(dateA.diff(dateB) > 0) {
                    return -1;
                }
                if(dateB.diff(dateA) > 0) {
                    return 1;
                }
                return 0;

            },
            ReloadData()
            {

                ApiService.getUsers(1,'DESC',6).then( response => {
                   this.ultimos_usuarios = response.data.data;
                   console.log('ultimos usuarios:');
                   console.log(this.ultimos_usuarios);

                });

                ApiService.getPlanners(1,'DESC',6).then( response => {
                   this.ultimos_renders = response.data.data;
                });

                ApiService.getNodos(1,'DESC',6).then(response => {
                    this.estado_nodos = response.data.data;
                });

                ApiService.getEventos(1,null,'DESC',6).then(response => {
                    this.ultimos_eventos = response.data.data;
                });

                ApiService.getAdminHomeStats().then(response => {
                   this.admin_home_stats = response.data;
                   console.log('admin home stats');
                   this.total_usuarios = this.admin_home_stats.total_usuarios;
                   this.usuarios_conectados = this.admin_home_stats.usuarios_conectados;
                   this.maximo_usuarios_online = this.admin_home_stats.maximo_online;
                   this.visiones_totales = this.admin_home_stats.visiones_totales;
                    this.espacio_libre = this.admin_home_stats.espacio_libre.split(".")[0];
                    this.likes_totales= this.admin_home_stats.likes_totales;
                   console.log(this.admin_home_stats);
                });

            },

        },
        mounted(){
          ApiService.getMe().then(r => {
              if(r.data.role != 'ROOT' && r.data.role != 'ADMIN' && r.data.role != 'SUPERVISOR')
              {
                  this.$swal.fire({
                      title: 'Acceso no autorizado',
                      text: 'No lo intentes, de aquí en adelante no tienes acceso',
                      type: 'error',
                      showCancelButton: false,
                  });
                  this.$router.push({path: '/'});
              }
              else {
                  this.show = true;
              }

          });

        },
        created(){


            ApiService.isMobile().then(response => {
                console.log('isMobile');
                console.log(response);
                this.is_mobile = response.data.is_mobile;
                if(localStorage.getItem('is_mobile'))
                {

                    this.is_mobile = localStorage.getItem('is_mobile');
                    console.log('leyendo is_mobile : ' + this.is_mobile );
                }

                else {
                    console.log('asignando is_mobile = ' + this.is_mobile);
                    localStorage.setItem('is_mobile', this.is_mobile);
                    if(this.is_mobile == true) {
                        localStorage.setItem('res_video', '360p');
                    }
                    else {
                        localStorage.setItem('res_video', '720p');
                    }
                    this.$forceUpdate();
                }
            });

            this.ReloadData();
        },
        computed: {

        }

    }


</script>

<style>
    html {
        scroll-behavior: smooth;
    }
</style>
